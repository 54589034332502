/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import ImageSlider from './ImageSlider';
// Import the icons from the correct path

// You can replace these icons with the appropriate JSX SVG hero icons for each feature
const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6 mr-2 text-green-500"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 13l4 4L19 7"
    />
  </svg>
);

const PricingTier = ({
  title,
  price,
  link,
  features,
  buttonText,
  buttonColor,
}) => {
  return (
    <div className="flex flex-col items-center p-6 max-w-md bg-black text-white rounded-3xl   shadow-lg border-purple-300 border-2">
      <h3 className="mb-4 text-xl md:text-2xl font-semibold ">{title}</h3>

      <div className="flex justify-center items-center">
        <p className=" text-3xl md:text-5xl font-bold  ">
          {price}{' '}
          <span className="text-base md:text-lg text-gray-400">/ month</span>{' '}
        </p>
      </div>
      {/* <button
        className={`mt-6 mb-8 px-6 py-3 bg-${buttonColor}-500 text-white rounded-md shadow-md hover:bg-${buttonColor}-600 focus:outline-none focus:ring-2 focus:ring-${buttonColor}-600 focus:ring-opacity-50`}
      >
        {buttonText}
      </button> */}
      {buttonText === 'Sold Out' ? (
        <a className="bg-gray-600 text-base md:text-l  px-4 py-2 my-4 rounded-full shadow-md transition-transform duration-300 ease-in-out hover:scale-105 flex justify-center items-center mx-auto w-fit cursor-not-allowed ">
          <strong>{buttonText}</strong>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="white"
            className="w-4 h-4 ml-2"
          >
            <path
              fillRule="evenodd"
              d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z"
              clipRule="evenodd"
            />
          </svg>
        </a>
      ) : (
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="bg-purple-600 text-base md:text-l  px-4 py-2 my-4 rounded-full shadow-md transition-transform duration-300 ease-in-out hover:scale-105 flex justify-center items-center mx-auto w-fit "
        >
          <strong>{buttonText}</strong>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="white"
            className="w-4 h-4 ml-2"
          >
            <path
              fillRule="evenodd"
              d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z"
              clipRule="evenodd"
            />
          </svg>
        </a>
      )}
      <ul className="flex-grow w-full">
        {features.map((feature, index) => (
          <li
            key={index}
            className="flex items-center mb-2 text-sm md:text-base"
          >
            <CheckIcon />
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Pricing = () => {
  const tiers = [
    {
      title: 'Standard',
      price: '$15',
      buttonText: 'Get Standard',
      buttonColor: 'blue',
      link: 'https://fanfuel.lemonsqueezy.com/checkout/buy/42272145-7e19-4dae-81a1-984eabeee6af',
      features: [
        '40 Ideas per month',
        '40 Metadata sets per month',
        '30 Thumbnails per month',
        '30 Video Scripts per month',
        '100 Analytics Chat interactions per month',
      ],
    },
    {
      title: 'Pro',
      price: '$35',
      buttonText: 'Get Pro',
      buttonColor: 'indigo',
      link: 'https://fanfuel.lemonsqueezy.com/checkout/buy/82145a4f-0e61-456f-bd62-629956e8296c',
      features: [
        '100 Ideas per month',
        '100 Metadata sets per month',
        '90 Thumbnails per month',
        '90 Video Scripts per month',
        'Unlimited Analytics Chat interactions per month',
        '10 Face Swaps for Thumbnails per month',
      ],
    },
    {
      title: 'Agency',
      price: '$90',
      buttonText: 'Sold Out',
      buttonColor: '',
      link: 'https://fanfuel.lemonsqueezy.com/checkout/buy/fe69e485-c696-41e9-8ed5-a8d7153de2b1',
      features: [
        'Unlimited Ideas',
        'Unlimited Metadata',
        'Unlimited Thumbnails',
        'Unlimited Video Scripts per month',
        'Unlimited Analytics Chat interactions per month',
        '200 Face Swaps for Thumbnails per month',

        'Advanced analytics',
        'Priority support',

        // Add any other exclusive features here
      ],
    },
  ];

  return (
    <div id="pricing">
      <ImageSlider />
      <div className="p-8">
        <h2 className="text-2xl font-bold text-center mb-4">Pricing</h2>
        <p className="text-center text-sm md:text-base md:font-semibold mb-8 text-white px-6 py-2 bg-slate-800 border-purple-300 border-2 rounded-xl md:rounded-full w-fit mx-auto shadow-purple-400 shadow-lg ">
          Cancel anytime. No hidden fees.
        </p>

        <div className="flex flex-wrap justify-center gap-4 md:gap-8  ">
          {tiers.map((tier, index) => (
            <div key={index} className="flex-1 min-w-[300px] max-w-[350px]">
              <PricingTier
                title={tier.title}
                price={tier.price}
                link={tier.link}
                buttonText={tier.buttonText}
                buttonColor={tier.buttonColor}
                features={tier.features}
              />
            </div>
          ))}
        </div>
        {/* <p className="text-center text-sm md:text-base md:font-semibold mt-8 text-white px-6 py-2 bg-slate-800 border-purple-300 border-2 rounded-xl md:rounded-full w-fit mx-auto shadow-purple-400 shadow-lg "> */}
        <p className="text-center text-md md:text-lg md:font-semibold mt-8  px-6 py-2">
          👋🏼 If you're a channel with 1k to 5k subscribers, reach out to us at{' '}
          <a className="text-bold underline" href="mailto:hello@fanfuel.social">
            hi@fanfuel.app
          </a>{' '}
          and snag a 30% discount on any plan.
          <br />
          We want to help you grow!
        </p>
      </div>
    </div>
  );
};

export default Pricing;
