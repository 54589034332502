/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import thumbnail from '../public/features/thumbnail.png';
import scriptmakervideo from '../public/features/scriptmakervideo.mp4';
import chat from '../public/features/chat.png';
import metadata from '../public/features/metatags.png';
import ideas from '../public/features/ideas.png';
import ImageSlider from './ImageSlider';
// Add your metadata and ideaSuggester imports here

// Placeholder SVG Icon components
const ThumbnailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#CA6BE5"
    className="w-5 h-5"
  >
    <path
      fillRule="evenodd"
      d="M20.599 1.5c-.376 0-.743.111-1.055.32l-5.08 3.385a18.747 18.747 0 0 0-3.471 2.987 10.04 10.04 0 0 1 4.815 4.815 18.748 18.748 0 0 0 2.987-3.472l3.386-5.079A1.902 1.902 0 0 0 20.599 1.5Zm-8.3 14.025a18.76 18.76 0 0 0 1.896-1.207 8.026 8.026 0 0 0-4.513-4.513A18.75 18.75 0 0 0 8.475 11.7l-.278.5a5.26 5.26 0 0 1 3.601 3.602l.502-.278ZM6.75 13.5A3.75 3.75 0 0 0 3 17.25a1.5 1.5 0 0 1-1.601 1.497.75.75 0 0 0-.7 1.123 5.25 5.25 0 0 0 9.8-2.62 3.75 3.75 0 0 0-3.75-3.75Z"
      clipRule="evenodd"
    />
  </svg>
);
const ScriptMakerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#CA6BE5"
    className="w-5 h-5"
  >
    <path
      fillRule="evenodd"
      d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625ZM7.5 15a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 7.5 15Zm.75 2.25a.75.75 0 0 0 0 1.5H12a.75.75 0 0 0 0-1.5H8.25Z"
      clipRule="evenodd"
    />
    <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
  </svg>
);
const ChatIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#CA6BE5"
    className="w-5 h-5"
  >
    <path
      fillRule="evenodd"
      d="M5.337 21.718a6.707 6.707 0 0 1-.533-.074.75.75 0 0 1-.44-1.223 3.73 3.73 0 0 0 .814-1.686c.023-.115-.022-.317-.254-.543C3.274 16.587 2.25 14.41 2.25 12c0-5.03 4.428-9 9.75-9s9.75 3.97 9.75 9c0 5.03-4.428 9-9.75 9-.833 0-1.643-.097-2.417-.279a6.721 6.721 0 0 1-4.246.997Z"
      clipRule="evenodd"
    />
  </svg>
);

const MetadataIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#CA6BE5"
    className="w-5 h-5"
  >
    <path
      fillRule="evenodd"
      d="M1.5 7.125c0-1.036.84-1.875 1.875-1.875h6c1.036 0 1.875.84 1.875 1.875v3.75c0 1.036-.84 1.875-1.875 1.875h-6A1.875 1.875 0 0 1 1.5 10.875v-3.75Zm12 1.5c0-1.036.84-1.875 1.875-1.875h5.25c1.035 0 1.875.84 1.875 1.875v8.25c0 1.035-.84 1.875-1.875 1.875h-5.25a1.875 1.875 0 0 1-1.875-1.875v-8.25ZM3 16.125c0-1.036.84-1.875 1.875-1.875h5.25c1.036 0 1.875.84 1.875 1.875v2.25c0 1.035-.84 1.875-1.875 1.875h-5.25A1.875 1.875 0 0 1 3 18.375v-2.25Z"
      clipRule="evenodd"
    />
  </svg>
);

const IdeaSuggesterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#CA6BE5"
    className="w-5 h-5"
  >
    <path d="M12 .75a8.25 8.25 0 0 0-4.135 15.39c.686.398 1.115 1.008 1.134 1.623a.75.75 0 0 0 .577.706c.352.083.71.148 1.074.195.323.041.6-.218.6-.544v-4.661a6.714 6.714 0 0 1-.937-.171.75.75 0 1 1 .374-1.453 5.261 5.261 0 0 0 2.626 0 .75.75 0 1 1 .374 1.452 6.712 6.712 0 0 1-.937.172v4.66c0 .327.277.586.6.545.364-.047.722-.112 1.074-.195a.75.75 0 0 0 .577-.706c.02-.615.448-1.225 1.134-1.623A8.25 8.25 0 0 0 12 .75Z" />
    <path
      fillRule="evenodd"
      d="M9.013 19.9a.75.75 0 0 1 .877-.597 11.319 11.319 0 0 0 4.22 0 .75.75 0 1 1 .28 1.473 12.819 12.819 0 0 1-4.78 0 .75.75 0 0 1-.597-.876ZM9.754 22.344a.75.75 0 0 1 .824-.668 13.682 13.682 0 0 0 2.844 0 .75.75 0 1 1 .156 1.492 15.156 15.156 0 0 1-3.156 0 .75.75 0 0 1-.668-.824Z"
      clipRule="evenodd"
    />
  </svg>
);

const Feature = ({ title, subtitle, children, styleClasses, icon }) => (
  <div
    className={`${styleClasses} p-4 shadow-purple-200 shadow-xl rounded3xl text-white bg-black border-80  h-full flex flex-col justify-between border-purple-300 border-2`}
  >
    {children}

    <div className="flex justify-center items-center mb-2 ">
      <h3 className="text-xl md:text-3xl font-bold text-center mr-2">
        {title}
      </h3>
      {icon}
    </div>
    <p className="text-sm md:text-base text-gray-300 text-center px-2">
      {subtitle}
    </p>
  </div>
);

const Features = () => {
  return (
    <section className="pt-20" id="features">
      {/* <ImageSlider /> */}
      <div className=" flex justify-center items-center w-fit mx-auto ">
        <h2 className="text-3xl font-bold text-center">Features</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="purple"
          className="w-8 h-8 ml-2"
        >
          <path
            fillRule="evenodd"
            d="M10.5 3.798v5.02a3 3 0 0 1-.879 2.121l-2.377 2.377a9.845 9.845 0 0 1 5.091 1.013 8.315 8.315 0 0 0 5.713.636l.285-.071-3.954-3.955a3 3 0 0 1-.879-2.121v-5.02a23.614 23.614 0 0 0-3 0Zm4.5.138a.75.75 0 0 0 .093-1.495A24.837 24.837 0 0 0 12 2.25a25.048 25.048 0 0 0-3.093.191A.75.75 0 0 0 9 3.936v4.882a1.5 1.5 0 0 1-.44 1.06l-6.293 6.294c-1.62 1.621-.903 4.475 1.471 4.88 2.686.46 5.447.698 8.262.698 2.816 0 5.576-.239 8.262-.697 2.373-.406 3.092-3.26 1.47-4.881L15.44 9.879A1.5 1.5 0 0 1 15 8.818V3.936Z"
            clipRule="evenodd"
          />
        </svg>
      </div>

      <div className="container mx-auto px-4 mt-10 lg:px-16">
        {' '}
        {/* Increased padding on large screens */}
        <div className="grid grid-cols-12 gap-4">
          {' '}
          {/* Change to 12-column grid */}
          {/* First row */}
          {/* Feature 1 */}
          <Feature
            title="AI Thumbnail Generator"
            subtitle="Stand out in the sea of videos with thumbnails that pop. Capture your audience’s attention instantly."
            styleClasses="col-span-12 md:col-span-7 rounded-3xl" // Adjusted for a 12-column grid
            icon={<ThumbnailIcon />}
          >
            <img
              src={thumbnail}
              alt="AI Thumbnail Generator"
              className="w-full object-cover rounded-t-lg mb-4"
            />
          </Feature>
          {/* Feature 2 */}
          <Feature
            title="Script Maker"
            subtitle="Overcome writer's block with scripts that resonate. Just give us a topic, and we’ll draft a script that echoes your voice and any language 🌍."
            styleClasses="col-span-12 md:col-span-5 rounded-3xl " // Adjusted for a 12-column grid
            icon={<ScriptMakerIcon />}
          >
            <video
              className="w-full object-cover rounded-t-lg mb-4"
              loop
              autoPlay
              muted
              controls={false}
            >
              <source src={scriptmakervideo} type="video/mp4" />
            </video>
          </Feature>
          {/* Second row */}
          {/* Feature 3 */}
          <Feature
            title=" AnalyticsChat"
            subtitle="Your stats have stories to tell. Our chat interface makes data easy to digest, so you can focus on creating, not crunching numbers."
            styleClasses="col-span-12 md:col-span-4 rounded-3xl " // One-third of the grid
            icon={<ChatIcon />}
          >
            <img
              src={chat}
              alt="Chat with your Analytics"
              className="w-full object-cover rounded-t-lg mb-4"
            />
          </Feature>
          {/* Feature 4 */}
          <Feature
            title="Metadata Maker"
            subtitle="Titles, tags, and descriptions that hit the mark. Save hours on SEO and get back to the content you love."
            styleClasses="col-span-12 md:col-span-4 rounded-3xl " // One-third of the grid
            icon={<MetadataIcon />}
          >
            <img
              src={metadata}
              alt=""
              className="w-full object-cover rounded-t-lg mb-4"
            />
            {/* Replace with your metadata image */}
          </Feature>
          {/* Feature 5 */}
          <Feature
            title="Idea Suggester"
            subtitle="Running low on content ideas? We analyze your hits and misses to suggest topics your audience will love."
            styleClasses="col-span-12 md:col-span-4 rounded-3xl" // One-third of the grid
            icon={<IdeaSuggesterIcon />}
          >
            <img
              src={ideas}
              alt=""
              className="w-full object-cover rounded-t-lg mb-4"
            />
            {/* Replace with your ideaSuggester image */}
          </Feature>
          {/* Add additional rows and features as needed */}
        </div>
      </div>
    </section>
  );
};

export default Features;
