import React from 'react';
import Marquee from 'react-fast-marquee';

const ImageSlider = () => {
  const images1 = [
    'https://kmycdexspalbhmonhodv.supabase.co/storage/v1/object/public/landingpge/thumbnails-landingpage/DALLE_2023-12-19_01.23.44_-_A_vibrant_and_dynamic_16_9_thumbnail_for_the_video_I_got_1_Victory_Royale_in_Fortnite._The_background_is_a_burst_of_vivid_colors_symbolizing_the_e.webp',
    'https://kmycdexspalbhmonhodv.supabase.co/storage/v1/object/public/landingpge/thumbnails-landingpage/img-cIkjQ7ExGd7FOZtvDePy5rvD%20(1).webp',
    'https://kmycdexspalbhmonhodv.supabase.co/storage/v1/object/public/landingpge/thumbnails-landingpage/img-ATlMQ07i9xY84AfxLwDxyVk2.webp',
    'https://kmycdexspalbhmonhodv.supabase.co/storage/v1/object/public/landingpge/thumbnails-landingpage/img-3hydzNLnsyT2kdHAsK5xkZHe%20(1).webp',
  ];
  const images2 = [
    'https://kmycdexspalbhmonhodv.supabase.co/storage/v1/object/public/landingpge/thumbnails-landingpage/GD6Nv3FbEAAjE44.jpeg',
    'https://kmycdexspalbhmonhodv.supabase.co/storage/v1/object/public/landingpge/thumbnails-landingpage/DALLE_2024-01-06_15.11.11_-_A_dynamic_and_high-energy_thumbnail_featuring_a_Lego_character_dressed_in_Fortnite_gear_surrounded_by_holiday_elements_like_snowflakes_Christmas_tre.webp',
    'https://kmycdexspalbhmonhodv.supabase.co/storage/v1/object/public/landingpge/thumbnails-landingpage/img-MCszFQxcz08Sst5TGOBTs19q%20(1).webp',
    'https://kmycdexspalbhmonhodv.supabase.co/storage/v1/object/public/landingpge/thumbnails-landingpage/img-wakLcXrq6RGrdBc49pdGLngV.webp',
  ];

  return (
    <div>
      <Marquee pauseOnHover={true} gradient={false}>
        {images1.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index}`}
            className=" h-40 md:h-72 rounded-lg  mr-4"
          />
        ))}
      </Marquee>
      <Marquee pauseOnHover={true} gradient={false} delay={4} className=" mt-4">
        {images2.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index}`}
            className=" h-40 md:h-72 rounded-lg mr-4"
          />
        ))}
      </Marquee>
    </div>
  );
};

export default ImageSlider;
