/* eslint-disable no-unused-vars */
import React from 'react';
import user1 from '../public/users/user1.png'; // Replace with your image paths
import user2 from '../public/users/user2.png';
import user3 from '../public/users/user3.png';
import user4 from '../public/users/user4.png';
import { default as posthog } from 'posthog-js';
import screenshot from '../public/screenshot.jpeg';
import youtubeicon from '../public/icons8-youtube-144.png';

const Hero = () => {
  return (
    <section className="text-center py-10 md:py-10">
      <div className="container mx-auto px-4">
        <div className="flex justify-center items-center my-4 bg-slate-300 px-4 rounded-full w-fit mx-auto">
          <p className=" font-medium ">Built for Youtube</p>
          <img src={youtubeicon} className="h-8 " alt="youtube icon" />
        </div>
        <h1
          className="text-3xl md:text-5xl mb-2 baseneue"
          style={{ lineHeight: '1.5' }}
        >
          The Fastest Way to
          {/* <span className="text-purple-500">Boost 
          </span>
          <br className="md:hidden" /> */}
          <br />
          <span className="bg-black text-white px-1"> Boost your Channel</span>
        </h1>
        <p className="text-base md:text-xl text-gray-500 my-6 md:my-10">
          Your AI Assistant For Youtube Scripts, Thumbnails, Analytics and
          Everything in Between!
        </p>
        {/* CTA Button */}
        <a
          href="#pricing"
          className="bg-black text-white text-lg md:text-xl px-4 md:px-6 py-2 md:py-3 rounded-full shadow-md transition-transform duration-300 ease-in-out hover:scale-105 flex justify-center items-center mx-auto w-fit border-purple-300 border-2"
          onClick={() =>
            posthog.capture('get_started_clicked', { property: 'value' })
          }
        >
          <strong>Get Started </strong>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#CA6BE5"
            className="w-6 h-6 ml-2"
          >
            <path
              fillRule="evenodd"
              d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z"
              clipRule="evenodd"
            />
          </svg>
        </a>

        {/* <p className=" font-medium my-4 ">No credit card required</p> */}
        <div className="flex justify-center items-center my-4">
          {/* Circular images for user count */}
          <img
            src={user1}
            alt="User 1"
            className="w-8 md:w-10 h-8 md:h-10 rounded-full border-2 border-white"
            style={{ zIndex: 4 }}
          />
          <img
            src={user2}
            alt="User 2"
            className="w-8 md:w-10 h-8 md:h-10 rounded-full border-2 border-white -ml-4"
            style={{ zIndex: 3 }}
          />
          <img
            src={user3}
            alt="User 3"
            className="w-8 md:w-10 h-8 md:h-10 rounded-full border-2 border-white -ml-4"
            style={{ zIndex: 2 }}
          />

          <img
            src={user4}
            alt="User 4"
            className="w-8 md:w-10 h-8 md:h-10 rounded-full border-2 border-white -ml-4"
            style={{ zIndex: 1 }}
          />
          <span className="font-medium text-sm md:text-base ml-2 text-gray-500">
            Used by 3000+ Happy Creators
          </span>
        </div>

        <img
          src={screenshot}
          alt="illustration"
          className=" md:w-10/12 mx-auto rounded-xl shadow-2xl my-10  "
        />
      </div>
    </section>
  );
};

export default Hero;
