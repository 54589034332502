import React from 'react';
import userFace from '../public/swapper/user.jpeg';
import aiThumbnail from '../public/swapper/target.jpg';
import swappedResult from '../public/swapper/result.png';

const PlusIcon = () => (
  <div className="mx-8">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#CA6BE5"
      className="w-6 h-6"
    >
      <path
        fillRule="evenodd"
        d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z"
        clipRule="evenodd"
      />
    </svg>
  </div>
);

const RightArrowIcon = () => (
  <div className="mx-8">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#CA6BE5"
      className="w-10 h-10"
    >
      <path
        fillRule="evenodd"
        d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z"
        clipRule="evenodd"
      />
    </svg>
  </div>
);

const ComingSoon = () => {
  return (
    <section className="pt-4">
      <div className="container mx-auto px-4 lg:px-16">
        <div className="text-center mb-4 bg-black text-white rounded-3xl py-8 main-shadow drop-shadow-2xl">
          <div className="text-center mb-4"></div>
          <div className="flex flex-col md:flex-row justify-evenly items-center p-2">
            {/* First Image */}
            <div className="flex flex-col items-center">
              <img
                src={userFace}
                alt="Your Face"
                className="rounded-md object-contain"
                style={{ maxHeight: '200px' }}
              />
              <p className="mt-2">Your Face</p>
              {/* <p className="text-xs">(Average Fanfuel user)</p> */}
            </div>

            {/* Plus Icon for mobile */}

            <PlusIcon />

            {/* Second Image */}
            <div className="flex flex-col items-center">
              <img
                src={aiThumbnail}
                alt="Our AI Thumbnail"
                className="rounded-md object-contain"
                style={{ maxHeight: '200px' }}
              />
              <p className="mt-2">Our AI Thumbnail</p>
            </div>
            <div className="rotate-90 md:rotate-0">
              <RightArrowIcon />
            </div>

            {/* Arrow Icon and Third Image on a new row for mobile */}
            <div className="w-full flex flex-col items-center mt-4 md:mt-0 md:w-auto">
              <div className="flex flex-col items-center">
                <img
                  src={swappedResult}
                  alt="Swapped Result"
                  className="rounded-md shadow-lg object-contain ring-8 ring-purple-500 ring-opacity-50"
                  style={{ maxHeight: '200px' }}
                />
                <p className="mt-4">Swapped Result</p>
              </div>
            </div>
          </div>
          <div className="text-center mt-4">
            <div className="flex justify-center items-center">
              <h3 className="text-xl md:text-2xl font-bold mb-2">
                Thumbnail Face Swapper
              </h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#CA6BE5"
                className="w-5 h-5 ml-2"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-2.625 6c-.54 0-.828.419-.936.634a1.96 1.96 0 0 0-.189.866c0 .298.059.605.189.866.108.215.395.634.936.634.54 0 .828-.419.936-.634.13-.26.189-.568.189-.866 0-.298-.059-.605-.189-.866-.108-.215-.395-.634-.936-.634Zm4.314.634c.108-.215.395-.634.936-.634.54 0 .828.419.936.634.13.26.189.568.189.866 0 .298-.059.605-.189.866-.108.215-.395.634-.936.634-.54 0-.828-.419-.936-.634a1.96 1.96 0 0 1-.189-.866c0-.298.059-.605.189-.866Zm2.023 6.828a.75.75 0 1 0-1.06-1.06 3.75 3.75 0 0 1-5.304 0 .75.75 0 0 0-1.06 1.06 5.25 5.25 0 0 0 7.424 0Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>

            <p className="text-sm md:text-base text-gray-300">
              Refresh your thumbnails with a new face, no photoshoot needed.
              Keep your channel fresh and engaging effortlessly.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComingSoon;
