// import logo from './logo.svg';
// import './App.css';

import ComingSoon from './components/ComingSoon';
import FAQSection from './components/FAQSection';
import Features from './components/Features';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Pricing from './components/Pricing';
// import UpcomingFeatures from './components/UpcomingFeature';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Features />
      <ComingSoon />
      {/* <UpcomingFeatures /> */}
      <FAQSection />
      <Pricing />
      <Footer />
    </div>
  );
}

export default App;
