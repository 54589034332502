/* eslint-disable react/prop-types */
import React, { useState } from 'react';

const faqs = [
  {
    question: 'Can I trust Fanfuel with my channel’s data?',
    answer:
      'Absolutely. We treat your data with the utmost confidentiality, guarding it with top-notch encryption and never sharing it without your consent.',
  },
  {
    question: 'Will I have to hand over my YouTube credentials?',
    answer:
      "Nope, there's no need. Fanfuel syncs with your YouTube account through official API integration, which means we get the data we need without ever seeing your login info.",
  },
  {
    question: 'Does Fanfuel AI work for content in every language?',
    answer:
      'Fanfuel supports a diverse range of languages for scriptwriting and AI narration, including English, Spanish, French, German, Italian, Japanese, Korean, and lots more...',
  },
  {
    question: 'How can I cancel my subscription if I need to?',
    answer:
      "We hope you'll stay, but if you must go, cancelling is easy and hassle-free. Just reach out to our support team at hello@fanfuel.social and we'll take care of it for you.",
  },
  {
    question: 'Does AI-generated content rank well for SEO?',
    answer:
      'Fanfuel’s AI-generated content is designed to be SEO-friendly. It creates relevant, engaging content that search engines love, helping improve your visibility and ranking.',
  },
  {
    question: "Does Fanfuel's AI-generated content sound natural?",
    answer:
      "We've worked hard to make sure our AI-generated content sounds as natural as possible. Our AI is trained on millions of data points to create content that's relevant, engaging, and easy to read.",
  },
];

const FAQItem = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b">
      <button
        className="py-4 w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex justify-between items-center">
          <p className="font-semibold">{faq.question}</p>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
      </button>
      {isOpen && <p className="pb-4">{faq.answer}</p>}
    </div>
  );
};

const FAQSection = () => {
  return (
    <section className="my-8 px-4">
      <h2 className="text-2xl font-bold text-center mb-4">
        Frequently Asked Questions
      </h2>
      <div className="max-w-2xl mx-auto">
        {faqs.map((faq, index) => (
          <FAQItem key={index} faq={faq} />
        ))}
      </div>
    </section>
  );
};

export default FAQSection;
