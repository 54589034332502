import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { default as posthog } from 'posthog-js';

posthog.init('phc_kC0us1tDP3jqvE6nqW4Zz2Jeec2s4qQX6XB0nU4BJqE', {
  api_host: 'https://us.posthog.com',
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
