import React from 'react';
import logo from '../public/fanfuel-final-logo.png';

const Navbar = () => {
  return (
    <nav className="text-black p-2 md:p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo and Site Name */}
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="h-8 md:h-10 rounded-md mr-2" />
          <span className="font-bold text-xl md:text-2xl">Fanfuel</span>
        </div>
        <div className="flex">
          {/* Pricing Link */}
          <div>
            <a
              href="#features"
              className="text-sm md:text-base text-black mr-6 hover:text-gray-700"
            >
              Features
            </a>
          </div>
          <div>
            <a
              href="https://fanfuel.lemonsqueezy.com/affiliates"
              target="_blank"
              rel="noreferrer"
              className="text-sm md:text-base text-black mr-6 hover:text-gray-700"
            >
              Affiliates
            </a>
          </div>

          {/* Pricing Link */}
          <div>
            <a
              href="#pricing"
              className="text-sm md:text-base text-black hover:text-gray-700"
            >
              Pricing
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
