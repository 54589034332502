import React from 'react';
// import microsoftlogo from '../public/microsoft.svg';
// import openailogo from '../public/openAI.svg';
import twitterlogo from '../public/icons8-twitter.svg';

const Footer = () => {
  return (
    <footer className="bg-black text-gray-300 py-8 mt-10">
      <div className="container mx-auto px-4 md:flex justify-between items-center flex-row-reverse ">
        {/* Footer Links */}
        <div className="text-center mb-4 md:mb-0">
          <div className="flex flex-row flex-wrap items-center justify-center ">
            <a
              href="https://fanfuel.social/terms"
              className="mr-2 md:mr-4 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
            |
            <a
              href="https://fanfuel.social/privacy-policy"
              className="mx-2 md:mx-4 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            |
            <a
              href="https://fanfuel.lemonsqueezy.com/affiliates"
              className="mx-2 md:mx-4 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Affiliates
            </a>
            |
            <a
              href="mailto:hello@fanfuel.social"
              className="mt-2 md:mt-0 md:ml-4 hover:underline"
            >
              Contact Us
            </a>
            <a
              href="https://x.com/fanfuelhq"
              className="mt-2 md:mt-0 md:ml-4 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitterlogo} alt="Twitter Logo" className="h-6 mx-2" />
            </a>
          </div>
          <p className="text-sm mt-2">
            &copy; {new Date().getFullYear()} Fanfuel. All rights reserved.
          </p>
        </div>

        {/* Grants Section */}
        {/* <div className="text-center mt-4 md:mt-0">
          <p className="text-sm mb-2">Grants by</p>
          <div className="flex justify-center items-center">
            <img
              src={microsoftlogo}
              alt="Microsoft Logo"
              className="h-6 mx-2"
            />
            <span className="mx-2">&#8226;</span>
            <img src={openailogo} alt="OpenAI Logo" className="h-6 mx-2" />
          </div>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
